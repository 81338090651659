import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const newsCarousels = document.querySelectorAll('.blog-carousel-block');

  if (0 < newsCarousels.length) {
    newsCarousels.forEach(function (newsCarousel) {
      new Swiper(newsCarousel.querySelector('.blog-carousel-block__carousel'), {
        draggable: true,
        loop: true,
        slidesPerView: 1.37,
        spaceBetween: 10,
        a11y: {
          paginationBulletMessage: '',
          nextSlideMessage: 'Prochaine nouvelle',
          prevSlideMessage: 'Nouvelle précédente',
        },
        navigation: {
          nextEl: newsCarousel
            .closest('.blog-carousel-block')
            .querySelector('.news-block__nav.swiper-button-next'),
          prevEl: newsCarousel
            .closest('.blog-carousel-block')
            .querySelector('.news-block__nav.swiper-button-prev'),
        },
        pagination: {
          el: newsCarousel
            .closest('.blog-carousel-block')
            .querySelector(
              '.blog-carousel-block__nav-container .swiper-pagination'
            ),
          type: 'fraction',
        },
        breakpoints: {
          600: {
            slidesPerView: 2,
          },
          769: {
            slidesPerView: 2.6,
          },
          1200: {
            slidesPerView: 3.635,
            spaceBetween: 30,
          },
        },
      });
    });
  }
});
